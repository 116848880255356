import React, { useState } from "react";
import Layout from "../../components/layout";

import E1 from '../../assets/UIF/FCE - EMI - 01 - DECLARACION JURADA PERSONA POLITICAMENTE EXPUESTA_V02.pdf'
import E2 from '../../assets/UIF/FCE - EMI - 02 - ASEGURADOS TOMADORES RG 126_2023 - U.I.F._V02.pdf'
import E3A from '../../assets/UIF/FCE - EMI - 03 - TOMADORES ASEGURADOS RG 126_2023 - U.I.F. - PERSONAS JURIDICAS - ANEXO.pdf'
import E3B from '../../assets/UIF/FCE - EMI - 03 - TOMADORES ASEGURADOS RG 126_2023 - U.I.F. - PERSONAS JURIDICAS_PRINCIPAL.pdf'
import E4 from '../../assets/UIF/FCE - EMI - 04 - INFORMACION A ASEGURADOS RG 126-2023 U.I.F. - ORGANISMOS PUBLICOS.pdf'
import E8 from '../../assets/UIF/FCE - EMI - 08 - DDJJ SUJETO OBLIGADO - RESOLUCION 126-2023 U.I.F..pdf'
import E15 from '../../assets/UIF/FCE - EMI - 15 - DECLARACION JURADA DEL PAGADOR DE LA POLIZA.pdf'
import S6 from '../../assets/UIF/FCE - SIN - 06 - UIF - PERSONA HUMANA.pdf'
import S7 from '../../assets/UIF/FCE - SIN - 07 - UIF - PERSONAS JURIDICAS.pdf'
import S10 from '../../assets/UIF/FCE - SIN - 10 - INFORMACION POR SINIESTRO RG 126 - 2023 - UIF.pdf'
import { Form } from "react-bootstrap";


export default function Lavado() {

  const [selectedForm, setSelectedForm] = useState("");

  const handleClickS = (event) => {
    setSelectedForm(event.target.value);
  };


  return (
    <Layout>
      <div className="container-xl p-0">
        <div className="pt-3 px-3 text-lg-left text-center">
          <div>
            <h5 className="text-company">
              I. POLITICA DE PREVENCIÓN DE LAVADO Y FINANCIAMIENTO DEL
              TERRORISMO
            </h5>

            <p>
              Comunicandose vía teléfono al{" "}
              <b className="text-company">0800 - 666 - 8400</b> o correo
              electrónico a{" "}
              <b className="text-company"> denuncias@ssn.gob.ar</b>{" "}
            </p>
            <hr />
            <p>
              La Compañía posee una conducta de acatamiento a las leyes,
              regulaciones y normas que le son de aplicación. Por lo tanto,
              respecto del grave tema de lavado de dinero que afecta el mundo
              entero y por las consecuencias dañosas que provoca en materia
              social y económica, corresponde adherir a la normativa dictada por
              el Estado Nacional y los Organismos de Control específicos en
              cuanto tiendan a prevenir e impedir la concreción de actividades
              delictivas. La Compañía posee una conducta de acatamiento a las
              leyes, regulaciones y normas que le son de aplicación. Por lo
              tanto, respecto del grave tema de lavado de dinero que afecta el
              mundo entero y por las consecuencias dañosas que provoca en
              materia social y económica, corresponde adherir a la normativa
              dictada por el Estado Nacional y los Organismos de Control
              específicos en cuanto tiendan a prevenir e impedir la concreción
              de actividades delictivas. Es por ello, que a los fines del
              correcto cumplimiento de las obligaciones establecidas en los
              artículos 20. y 21 bis de la Ley Nº 25.246 y sus modificatorias,{" "}
              <b>EDIFICAR Seguros S.A.</b> adoptó una política de prevención en
              materia de Lavado de Activos, Financiación del Terrorismo y
              Financiamiento de la Proliferación, de conformidad con la RG
              126/2023 emitida por la Unidad de Información Financiera.
            </p>
          </div>

          <div>
            <h4 className="text-company">
              La misma contempla los siguientes aspectos:
            </h4>
            <div className="text-left pl-lg-3">
              <p>
                <b className="text-company">a)</b> La obligación de implementar
                un Sistema de Prevención de Lavado de Activos y Financiamiento
                del Terrorismo y de la Proliferación con un enfoque basado en
                riesgos que deberá contener las políticas, procedimientos y
                controles a fin de identificar, evaluar, monitorear, administrar
                y mitigar eficazmente los riesgos de PLA/FT/FP a que se
                encuentra expuesta la organización.
              </p>

              <p>
                <b className="text-company">b)</b> La elaboración de un manual
                que contiene los mecanismos y procedimientos para la prevención
                del Lavado de Activos y la Financiación del Terrorismo adoptados
                por EDIFICAR Seguros S.A.
              </p>

              <p>
                <b className="text-company">c)</b> la obligación de detectar a
                los clientes Persona Expuesta Políticamente y Sujetos Obligados.
              </p>

              <p>
                <b className="text-company">d)</b> La designación de un
                Oficial de Cumplimiento conforme lo establece el artículo 21
                inc. f) de la Ley Nº 25.246 y sus modificatorias y el artículo
                20 del Decreto Nº 290/07 y modificatorio.
              </p>

              <p>
                <b className="text-company">e)</b> La implementación de
                auditorías periódicas.
              </p>

              <p>
                <b className="text-company">f)</b> La capacitación a todo el
                personal.
              </p>

              <p>
                <b className="text-company">g)</b> La elaboración de registros
                de análisis y gestión de riesgo de las operaciones inusuales
                detectadas y aquellas que por haber sido consideradas
                sospechosas hayan sido reportadas, en el caso de existir.
              </p>

              <p>
                <b className="text-company">h)</b> La implementación de
                herramientas tecnológicas, que le permiten establecer de una
                manera eficaz los sistemas de control y prevención de Lavado de
                Activos, Financiación del Terrorismo y Financiamiento de la
                Proliferación.
              </p>

              <p>
                <b className="text-company">i)</b> La implementación de medidas
                que le permite a EDIFICAR Seguros S.A. consolidar
                electrónicamente las operaciones que realizan con sus clientes,
                así como herramientas que le posibilitan analizar o monitorear
                distintas variables para identificar ciertos comportamientos y
                visualizar posibles operaciones sospechosas.
              </p>
            </div>
          </div>

          <p style={{ fontSize: "14px" }}>
            <b>
              Ante cualquier consulta puede enviar un mail a{" "}
              <span className="text-company">
                oficialdecumplimiento@edificarseguros.com.ar
              </span>
            </b>
          </p>
        </div>

        <hr />

        <h3 className="d-block text-center">Formularios disponibles</h3>

        <Form.Group className="py-2" controlId="exampleForm.ControlSelect2">
            <Form.Label className="d-block text-center"></Form.Label>
            <Form.Control as="select" onChange={handleClickS}>
              <option value="">Seleccione un formulario</option>
              <option value={E1}>FCE - EMI - 01 - DECLARACION JURADA PERSONA POLITICAMENTE EXPUESTA_V02</option>
              <option value={E2}>FCE - EMI - 02 - ASEGURADOS TOMADORES RG 126_2023 - U.I.F._V02</option>
              <option value={E3A}>FCE - EMI - 03 - TOMADORES ASEGURADOS RG 126_2023 - U.I.F. - PERSONAS JURIDICAS - ANEXO</option>
              <option value={E3B}>FCE - EMI - 03 - TOMADORES ASEGURADOS RG 126_2023 - U.I.F. - PERSONAS JURIDICAS_PRINCIPAL</option>
              <option value={E4}>FCE - EMI - 04 - INFORMACION A ASEGURADOS RG 126-2023 U.I.F. - ORGANISMOS PUBLICOS</option>
              <option value={E8}>FCE - EMI - 08 - DDJJ SUJETO OBLIGADO - RESOLUCION 126-2023 U.I.F.</option>
              <option value={E15}>FCE - EMI - 15 - DECLARACION JURADA DEL PAGADOR DE LA POLIZA</option>
              <option value={S6}>FCE - SIN - 06 - UIF - PERSONA HUMANA</option>
              <option value={S7}>FCE - SIN - 07 - UIF - PERSONAS JURIDICAS</option>
              <option value={S10}>FCE - SIN - 10 - INFORMACION POR SINIESTRO RG 126 - 2023 - UIF</option>
            </Form.Control>
            {selectedForm && (
              <a
                className="border-0 p-2 btn-block btn-blue text-center text-light mt-2"
                href={selectedForm}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                Descargar formulario
              </a>
            )}
          </Form.Group>


      </div>
    </Layout>
  );
}
