import React, { useState } from 'react'
import { Form, Button, Row, Col, Container, Alert } from 'react-bootstrap'
import Layout from './layout'
import styled from 'styled-components'
import emailjs from 'emailjs-com'

const Div = styled.div`

    padding: 10px;
    margin-top: 20px;
    
    Form {
        border-radius: 10px;
        padding:15px;
    }

    .alert {
        display: flex;
        justify-content: space-between;
    }

    .alert-heading {
        margin: 0;
        display: flex;
        align-items: center;
        font-size: 18px;
    }


`

export default function AnulacionPoliza() {
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm('service_062v5xh', 'template_qbr904s', e.target, 'user_1FHqsxDEs8xr6xl7iASNU')
            .then((result) => {
                console.log(result.text);
                setShow(true)
            }, (error) => {
                console.log(error.text);
                setShow2(true)
            });
        e.target.reset()
    }


    return (



        <Layout>



            <Div>
                <Container>
                    <Row>
                        <Col lg={{ span: 6, offset: 3 }}>

                            <Alert show={show} variant="success">
                                <Alert.Heading>Formulario Enviado</Alert.Heading>
                                <div className="d-flex justify-content-end">
                                    <Button onClick={() => setShow(false)} variant="outline-success">
                                        cerrar
                                    </Button>
                                </div>
                            </Alert>

                            <Alert show={show2} variant="danger">
                                <Alert.Heading>Ocurrió un error, vuelva a intentarlo mas tarde</Alert.Heading>
                                <div className="d-flex justify-content-end">
                                    <Button onClick={() => setShow2(false)} variant="outline-danger">
                                        cerrar
                                    </Button>
                                </div>
                            </Alert>

                            <h3> <b>Formulario de arrepentimiento</b> </h3>
                            <hr />

                            <p>Si sos tomador de alguno de nuestros productos, a través de este formulario podrás solicitar la anulación de tu póliza.
                                Por favor, completa los siguientes datos y nos pondremos en contacto en breve:</p>

                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{ span: 6, offset: 3 }}>
                            <Form onSubmit={sendEmail}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>* Apellido y Nombre</Form.Label>
                                    <Form.Control type="text" name="name" placeholder="Ingresar Apellido" required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>* DNI</Form.Label>
                                    <Form.Control type="text" name="dni" placeholder="Ingresar DNI" required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>* Email</Form.Label>
                                    <Form.Control type="email" name="email" placeholder="Ingresar email" required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>* Teléfono</Form.Label>
                                    <Form.Control type="phone" name="phone" placeholder="Ingresar Teléfono" required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>* Tipo de Producto</Form.Label>
                                    <Form.Control type="text" name="product" placeholder="Ingresar Tipo de producto" required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>* Comentanos el motivo del arrepentimiento</Form.Label>
                                    <Form.Control as="textarea" name="comments" rows={3} required />
                                </Form.Group>


                                <Button variant="primary" type="submit">
                                    Enviar
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Div>

        </Layout>

    )

}

