import React from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'

import AAIP from '../assets/aaip.png'
import QR from '../assets/QR.png'

export default function ModalQr() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <a onClick={handleShow} style={{ cursor: 'pointer' }}>
          <img src={AAIP} alt="" width="29" />
        </a>
  
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title className="text-center w-100">QR</Modal.Title>
            </Modal.Header>
            <Modal.Body className="m-auto text-center">
              <img className="m-auto d-block" width="150px" src={QR} alt=""/>
              <br/>
              <p>Conocé tus datos personales en base de datos</p>
              <p>
                Para más información ingresá a: <br/>
                <a href="http://www.argentina.gob.ar/aaip" target="_blank">www.argentina.gob.ar/aaip</a>         
              </p>
            </Modal.Body>
        </Modal>


      </>

    );

}

