import React from 'react'
import Layout from '../../components/layout'

export default function Fraude()  {

    return(
        <Layout>
            <div className="container-xl p-0">

                <div className="px-3 pt-3 text-lg-left text-center">
                    <h5 className="text-company">II. PREVENCIÓN Y LUCHA CONTRA EL FRAUDE</h5>
                    <hr/>
                    <p>
                        De acuerdo a lo establecido en la Resolución Nº 38.477 del ente regulador de la actividad aseguradora sobre políticas, procedimientos y controles internos para <b>combatir el fraude</b>, comunicamos a nuestros asegurados, intermediarios, auxiliares de la actividad aseguradora y a terceros relacionados, que nuestra compañía cuenta con acciones tendientes a prevenir y detectar el fraude, dando cumplimiento a la normativa vigente en la materia. En este contexto, se entiende por fraude <b>a toda acción u omisión perpetrada en el marco de una relación de seguros, incluyendo la conducta de comercializadores no autorizados, para recabar una ventaja o beneficio indebido para provecho propio o de un tercero</b>. Téngase presente que el fraude afecta a toda la comunidad e incide fuertemente en el costo de las primas, razón que nos impulsa a seguir previniendo para su beneficio.
                        Para combatir este flagelo se aconseja seguir las siguientes recomendaciones:
                    </p>

                    <ul className="text-left pl-4">
                        <li>Nunca firmar en blanco formularios de reclamos o denuncias de siniestros.</li>
                        <li>Nunca debe aceptar dinero o suscribir documentos o acordar apoderamientos cuyos alcances y efectos no comprendan.</li>
                        <li>Nunca deben facilitar los datos ni el acceso de sus pólizas a terceros cuando ello no se justifique.</li>
                        <li>Siempre deben consultar a la aseguradora ante cualquier duda o eventualidad y comunicar novedades que surjan.</li>
                    </ul>

                    <p>Asimismo solicitamos su colaboración para que en caso de tomar conocimiento sobre alguna actividad fraudulenta relacionada a la empresa, se comunique al <b className="text-company">0800-666-8400</b>, interno 3268 o enviando un e-mail a <b className="text-company">fraude@edificarseguros.com.ar</b></p>

                </div>
                

            </div>
        </Layout>

    )

}