import React from 'react'
import Layout from '../components/layout'
import Slider from '../components/slider'

import ImgA from '../assets/4A.png'
import ImgB from '../assets/5A.jpg'
import ImgC from '../assets/6A.jpg'


import styled from 'styled-components'


const Div = styled.div`

    .features {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
    }

    .features h3 {
        text-align: center;
    }

    .features div {
        background: #e85a20;
        border-radius: 10px;
        padding: 30px;
        box-shadow: 1px 4px 5px #fb9b74;
        color: white;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
    }

    .features div:hover {
        transform: scale(1.05);
    }

    @media screen and (max-width: 996px) {

        .features {
            grid-template-columns: 1fr;
            grid-gap: 20px;
        }

        .features div:hover {
            transform: scale(1);
        }

    }

`




export default function About()  {

    return(
        <Layout>
            <Div className="container-xl p-0" >

                <div className="px-2 text-lg-left text-center">
                    <h3 className="pt-3 text-company"><b>Quiénes Somos?</b></h3>
                    <p>Somos una compañía conformada íntegramente por capitales nacionales. Brindamos seguridad en las personas mediante una atención de excelencia, asegurando el profesionalismo de los que componen la compañía, y acompañando a los clientes en la preservación del patrimonio desde hace más de una década. Sabemos que la familia es lo más importante, por ello en Edificar Seguros contamos con una cobertura personalizada para proteger eso que tanto cuidas, ofreciendo el respaldo económico que la misma necesita ante cualquier imprevisto.</p>
                    <p>Nos diferenciamos en el mercado por contar con una estructura operativa dinámica, ágil y eficiente, con una alta solidez patrimonial y financiera, brindando un servicio de atención personalizada acorde a los requerimientos de los asegurados.</p>

                    <div className="features">
                        <div>
                            <h3>Misión</h3>
                            <p>Queremos brindar seguridad a las personas mediante una atención de excelencia, asegurando el profesionalismo de los que componen la compañía acompañando a nuestros clientes en la preservación del patrimonio</p>
                        </div>
                        <div>
                            <h3>Visión</h3>
                            <p>Ampliar nuestras propuestas de servicios acorde a las necesidades del cliente.</p>
                        </div>
                        <div>
                            <h3>Valores</h3>
                            <p>Honestidad, confidencialidad, confianza, esfuerzo, proactividad, creatividad</p>
                        </div>
                    </div>
                </div>
                

                <div className="pt-4 px-0">
                    <Slider  ImgA={ImgA} ImgB={ImgB} ImgC={ImgC} />
                </div>

            </Div>
        </Layout>

    )

}