import React from 'react';
import './App.css';
import Layout from './components/layout';
import Slider from './components/slider';
import { Link } from "react-router-dom";
import styled from 'styled-components';

import Img0 from './assets/banner.png'
import ImgApp from './assets/banner-app.png'
import GooglePlay from './assets/googleplay.png'
import AppStore from './assets/appstore.png'
import ImgAppRes from './assets/app-responsive.png'
import ImgA from './assets/1A.jpg'
import ImgB from './assets/2A.jpg'
import ImgC from './assets/3A.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
library.add(fas)


const Div = styled.div`

  .button-wtp {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 60px 0px;
  }

  #btn-whatsapp {
    display: flex;
    background: #82c91eec;
    padding: 10px;
    color: white;
    justify-content: center;
  }

  .portada-app-responsive {
    display: none;
  }

  .portada-app {
    position: relative;
  }

  .portada-app .googleplay {
    position: absolute;
    bottom: 20%;
    left: 5%;
    width: 30%;
  }

  .portada-app .appstore {
    position: absolute;
    bottom: 20%;
    left: 35%;
    width: 30%;
  }

  @media screen and (max-width: 992px) {

    // .slide {
    //   display: none;
    // }
    
    .button-wtp {
      grid-template-columns: 1fr;
    }


    .portada-app-responsive {
      display: block;
    }

    .portada-app .googleplay-res {
      
    }

    .portada-app {
      display: none;
    }

    .icon-fab {
      position: fixed;
      z-index: 999;
      border-radius: 50px;
      background: #82c91eec;
      right: 10px;
      bottom: 20px;
    }

    .icon-fab:hover {
      background: #89d222ec;
    }


  }

  .img-banner {
    position: relative;
  }

  .img-banner::before {
    content: "";
    background: radial-gradient(transparent 80%,white 90%);
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  #client {
    background: #0074af;
    color: white;
  }

  #client-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    grid-gap: 20px;
  }

  #client-grid div {
    width:100%;
    padding: 0 5px;
  }

  #contact-us {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
  
  #services {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    cursor:pointer;
  }

  #services div:hover {
    transform: scale(1.1);
  }

  #services div{
    box-shadow: 1px 4px 10px grey;
    padding: 25px;
    font-size: 14px;
    transition: all 0.5s ease-in-out
  }

  #services div p{
    text-align: left
  }

  @media screen and (max-width: 996px) {

    #services div:hover {
      transform: scale(1);
    }

    .Legal a {
      padding: 10px !important
    }

    #client-grid {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      grid-gap: 20px;
    }

    #contact-us {
      grid-template-columns: 1fr;
    }

    #services {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
  }


}`;


function App() {

  return (
    <Layout>
      <Div>

        <div className="container-xl p-0">

          <Slider ImgA={ImgA} ImgB={ImgB} ImgC={ImgC} />

          <section id="about" className="bg-edificar text-center text-light p-5 rounded-bottom">

            <h2 className="py-3">Institucional</h2>
            <p>Edificar Seguros está conformado por un equipo de profesionales capacitados para brindar un excelente servicio en seguros. Tenemos una vasta experiencia, realizamos un seguimiento diario para poder brindarle un servicio de asistencia e innovación en el mercado de seguros.</p>

            <Link to="/sobre-nosotros">
              <button className="btn-edificar color-light btn-lg mt-3">Sobre Nosotros</button>
            </Link>

          </section>

          <section className="py-5">
            <a id="services" name="services"></a>
            <h2 className="text-center py-3 text-company">Servicios</h2>
            <div id="services">
              <div className="text-center">
                <FontAwesomeIcon icon="users" className="py-3 fa-3x" color="#E85A20" style={{ height: '80px' }} />
                <h6>Seguro de Vida Colectivo</h6>
                <hr />
                <p>A través de los seguros colectivos de vida, las Empresas pueden brindar a sus empleados planes eficaces de protección por sumas aseguradas acordes a sus necesidades, conviertiéndose en una excelente herramienta para atraer y fidelizar al personal. La indemnización será pagadera a los beneficiarios en caso de producirse el fallecimiento.</p>
              </div>
              <div className="text-center">
                <FontAwesomeIcon icon="briefcase" className="py-3 fa-3x" color="#E85A20" style={{ height: '80px' }} />
                <h6>Seguro de Desempleo</h6>
                <hr />
                <p>Esta cobertura de seguro ofrece respaldo económico ante el acaecimiento del desempleo involuntario del Trabajador/a bajo relación de dependencia. Para acceder a la presente cobertura, deberá contar con una antigüedad mínima en el empleo de 6 (seis) meses.</p>
              </div>
              <div className="text-center">
                <FontAwesomeIcon icon="user" className="py-3 fa-3x" color="#E85A20" style={{ height: '80px' }} />
                <h6>Seguro Colectivo de Vida Ley 20.744</h6>
                <hr />
                <p>Esta cobertura ofrece respaldo económico a los empleadores que se vean obligados a afrontar los pagos indemnizatorios establecidos en los artículos 212, 4º párrafo y 248 de la Ley de Contrato de Trabajo N.º 20.744 Para los supuestos de Incapacidad Total y Permanente o Fallecimiento respectivamente, hasta la edad máxima establecida en las condiciones particulares y con una antigüedad mayor a los 3 (tres) meses.</p>
              </div>
              <div className="text-center">
                <FontAwesomeIcon icon="university" className="py-3 fa-3x" color="#E85A20" style={{ height: '80px' }} />
                <h6>Seguro Colectivo de Vida Obligatorio</h6>
                <hr />
                <p>Todo empleador está obligado a contar con un Seguro Colectivo de Vida Obligatorio con el objetivo  de asegurar el riesgo de muerte de sus trabajadores en relación de dependencia. La falta de contratación de éste seguro hace directamente responsable al empleador del pago del beneficio respectivo.</p>
              </div>
              <div className="text-center">
                <FontAwesomeIcon icon="heart" className="py-3 fa-3x" color="#E85A20" style={{ height: '80px' }} />
                <h6>Seguro de Vida con Ahorro</h6>
                <hr />
                <p>Se trata de un seguro de vida individual, el cual combina la cobertura de la vida y el ahorro a largo plazo, permitiendo la capitalización diaria del ahorro mediante la inversión en un fondo especial y el pago a los beneficiarios de una suma asegurada en caso de fallecimiento del asegurado.</p>
                <p>La cuenta individual podrá ser consultada a través de la plataforma MI SEGURO.</p>
              </div>
            </div>
          </section>

        </div>

        <section id="client" className="py-5 text-center px-0">
          <div className="container-xl">
            <h2 className="text-center py-3">Información para clientes</h2>
            <div id="client-grid" className="w-100">
              <div>
                <Link to="/preguntas-frecuentes"><button className="border-0 btn-block btn-blue p-3">Preguntas Frecuentes</button></Link>
                <p className="m-0 mt-3 text-center text-lg-left"><strong>¿Cómo denuncio un siniestro?</strong></p>
                <p className="text-center text-lg-left">Deberás solicitar en nuestras oficinas el formulario de denuncia correspondiente a la cobertura que deseás.</p>
              </div>
              <div>
                <Link to="/formularios"><button className="border-0 btn-block btn-blue p-3">Formularios</button></Link>
                <p className="m-0 mt-3">Aquí podrá descargar los formularios para realizar los trámites requeridos</p>
              </div>
              <div>
                <Link to="/anulacion-poliza"><button className="border-0 btn-block btn-blue p-3">Arrepentimiento de Contratación</button></Link>
                <p className="m-0 mt-3">Aquí podrás Solicitar la Anulación de la Póliza</p>
              </div>
            </div>
          </div>
        </section>

        <div className="container-xl">

          <section>
            <div className="portada-app">
              <img width="100%" src={ImgApp} alt="" />


              <a className="googleplay" href="https://play.google.com/store/apps/details?id=io.edificarseguros.mobileapp" target="_blank">
                <img width="100%" src={GooglePlay} alt="" />
              </a>

              <a className="appstore" href="https://apps.apple.com/us/app/edificar-m%C3%B3vil/id1479703568" target="_blank">
                <img width="100%" src={AppStore} alt="" />
              </a>

            </div>

            <div className="portada-app-responsive">

              <img width="100%" src={ImgAppRes} alt="" />

              <a className="googleplay-res" href="https://play.google.com/store/apps/details?id=io.edificarseguros.mobileapp" target="_blank">
                <img width="100%" src={GooglePlay} alt="" />
              </a>

              <a className="appstore-res" href="https://apps.apple.com/us/app/edificar-m%C3%B3vil/id1479703568" target="_blank">
                <img width="100%" src={AppStore} alt="" />
              </a>

            </div>
          </section>

          <section id="contact" className="py-5">
            <a id="contactus"></a>
            <h2 className="text-center pt-3 text-company">Contactanos</h2>
            <p className="text-center pb-3"></p>

            <div id="contact-us">
              <div className="text-center">
                <FontAwesomeIcon icon="phone" className="py-3 fa-3x" color="#E85A20" style={{ height: '60px' }} />
                <p>0810-999-3343</p>
                <hr />
                <FontAwesomeIcon icon="envelope-open" className="py-3 fa-3x" color="#E85A20" style={{ height: '60px' }} /> <br />
                <a href="info@edificarseguros.com.ar">info@edificarseguros.com.ar</a>
              </div>
              <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.0042642080984!2d-58.39595878383755!3d-34.604053680459245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccac1d03d9b37%3A0x48409fbb4770873!2sRiobamba%20429%2C%20C1006%20ACC%2C%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1594538001145!5m2!1ses-419!2sar" width="100%" height="100%" frameBorder="0" className="border-0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
              </div>
            </div>

            <hr />

            <div className="button-wtp">
              <div style={{ alignSelf: 'center' }} className="text-center pb-3">
                <p className="contactowtp m-0">INGRESA A NUESTRO CANAL DE WHATSAPP PARA CONSULTAS</p>
              </div>

              <a id="btn-whatsapp" className="btn btn-block" href="http://wa.me/541122756141" style={{ alignSelf: 'baseline' }} target="_blank">
                <div className="text-center">
                  <FontAwesomeIcon icon={faWhatsapp} className="py-3 fa-5x" color="#fffff" />
                </div>
                <div style={{ alignSelf: 'center' }}>
                  <p className="contactowtp m-0">CONSULTAS</p>
                </div>
              </a>
              
            </div>

            <hr />

          </section>

        </div>

        <div className="icon-fab">
          <a id="btn-whatsapp-phone" href="http://wa.me/541122756141" target="_blank">
            <FontAwesomeIcon icon={faWhatsapp} className="py-3 fa-5x" style={{ height: '70px' }} color="white" />
          </a>
        </div>

      </Div>
    </Layout>
  );
}

export default App;
