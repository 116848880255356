import React, { useState } from 'react'
import Layout from '../components/layout'
import { Form } from 'react-bootstrap'

import S1 from '../assets/files/FCE - SIN - 01 - DENUNCIA DE SINIESTRO POR FALLECIMIENTO - ART_ 248 LCT.pdf'
import S2 from '../assets/files/FCE - SIN - 02 - DENUNCIA DE SINIESTRO POR INVALIDEZ - ART_ 212 LCT.pdf'
import S3 from '../assets/files/FCE - SIN - 03 - ENFERMEDAD Y-O ACCIDENTE INCULPABLE.pdf'
import S4 from '../assets/files/FCE - SIN - 04 - DENUNCIA DE SINIESTRO POR FALLECIMIENTO.pdf'
import S5 from '../assets/files/FCE - SIN - 05 - DENUNCIA DE SINIESTRO POR DESEMPLEO.pdf'
import S6 from '../assets/files/FCE - SIN - 06 - UIF - PERSONA HUMANA.pdf'
import S7 from '../assets/files/FCE - SIN - 07 - UIF - PERSONAS JURIDICAS.pdf'
import S8 from '../assets/files/FCE - SIN - 08 - DENUNCIA DE SINIESTRO POR MATERNIDAD.pdf'
import S9 from '../assets/files/FCE - SIN - 09 - SEGURO COLECTIVO DE VIDA OBLIGATORIO.pdf'
import S10 from '../assets/files/FCE - SIN - 10 - INFORMACION POR SINIESTRO RG 126 - 2023 - UIF.pdf'
import S11 from '../assets/files/FCE - SIN - 11 - DENUNCIA DE SINIESTRO SEGUROS DE PERSONAS.pdf'
import S12 from '../assets/files/FCE - SIN - 12 - DENUNCIA DE SINIESTRO DE VIDA CON AHORRO.pdf'
import S13 from '../assets/files/FCE - SIN - 13 - DECLARACION JURADA DEL BENEFICIARIO - CESIONARIO.pdf'

import I1 from '../assets/files/FCE - INV - 01 - SOLICITUD DE RESCATE - VIDA CON AHORRO.pdf'

import E1 from '../assets/files/FCE - EMI - 01 - DECLARACION JURADA PERSONA POLITICAMENTE EXPUESTA_V02.pdf'
import E2 from '../assets/files/FCE - EMI - 02 - ASEGURADOS TOMADORES RG 126_2023 - U.I.F._V02.pdf'
import E3 from '../assets/files/FCE - EMI - 03 - TOMADORES ASEGURADOS RG 126_2023 - U.I.F. - PERSONAS JURIDICAS_PRINCIPAL.pdf'
import E3A from '../assets/files/FCE - EMI - 03 - TOMADORES ASEGURADOS RG 126_2023 - U.I.F. - PERSONAS JURIDICAS - ANEXO.pdf'
import E4 from '../assets/files/FCE - EMI - 04 - INFORMACION A ASEGURADOS RG 126-2023 U.I.F. - ORGANISMOS PUBLICOS.pdf'
import E5 from '../assets/files/FCE - EMI - 05 - FORMULARIO DE BENEFICIARIOS.pdf'
import E6 from '../assets/files/FCE - EMI - 06 - SEGURO DE VIDA OBLIGATORIO - SOLICITUD DE POLIZA.pdf'
import E7 from '../assets/files/FCE - EMI - 07 - SCV - ADHESION Y DESIGNACION DE BENEFICIARIOS.pdf'
import E8 from '../assets/files/FCE - EMI - 08 - DDJJ SUJETO OBLIGADO - RESOLUCION 126-2023 U.I.F..pdf'
import E12 from '../assets/files/FCE - EMI - 12 - SOLICITUD AFILIACION VIDA CON AHORRO.pdf'
import E15 from '../assets/files/FCE - EMI - 15 - DECLARACION JURADA DEL PAGADOR DE LA POLIZA.pdf'

export default function Forms()  {

    const [ formsS, setFormsS ] = useState('')
    const [ formsI, setFormsI ] = useState('')
    const [ formsE, setFormsE ] = useState('')


    function handleClickS(e) {
        var form = setFormsS( form = e.target.value )
        return form;
    }

    function handleClickI(e) {
        var form = setFormsI( form = e.target.value )
        return form;
    }

    function handleClickE(e) {
        var form = setFormsE( form = e.target.value )
        return form;
    }

    
    return(
        <Layout>
            <div className="container-xl p-0">

                <div className="px-2 px-lg-0">

                    <div className="pl-2 text-lg-left">
                        <h3 className="pt-3 text-lg-left text-company"><b>Formularios</b></h3>
                        <hr />
                        <p><strong>IMPORTANTE!</strong> Los formularios pueden editarse digitalmente.</p>
                        <p>La entrega del formulario debe efectuarse:</p>
                        <ul className="px-5 px-lg-4 text-left">
                            <li>en digital</li>
                            <li>debe estar impreso en doble faz</li>
                            <li>debe presentarse debidamente firmado sin tachaduras ni enmiendas</li>
                        </ul>
                    </div>

                    <Form.Group className="py-2" controlId="exampleForm.ControlSelect2">
                        <Form.Label className="d-block text-center">Formularios de Siniestros</Form.Label>
                        <Form.Control as="select" onChange={handleClickS}>
                            <option></option>
                            <option value={S1}>FCE - SIN - 01 - DENUNCIA DE SINIESTRO POR FALLECIMIENTO - ART_ 248 LCT</option>
                            <option value={S2}>FCE - SIN - 02 - DENUNCIA DE SINIESTRO POR INVALIDEZ - ART_ 212 LCT</option>
                            <option value={S3}>FCE - SIN - 03 - DENUNCIA DE SINIESTRO POR ENFERMEDAD Y-O ACCIDENTE INCULPABLE</option>
                            <option value={S4}>FCE - SIN - 04 - DENUNCIA DE SINIESTRO POR FALLECIMIENTO - ART_ 27</option>
                            <option value={S5}>FCE - SIN - 05 - DENUNCIA DE SINIESTRO POR DESEMPLEO</option>
                            <option value={S6}>FCE - SIN - 06 - UIF - PERSONA HUMANA</option>
                            <option value={S7}>FCE - SIN - 07 - UIF - PERSONAS JURIDICAS</option>
                            <option value={S8}>FCE - SIN - 08 - DENUNCIA DE SINIESTRO POR MATERNIDAD</option>
                            <option value={S9}>FCE - SIN - 09 -SEGURO COLECTIVO DE VIDA OBLIGATORIO</option>
                            <option value={S10}>FCE - SIN - 10 - INFORMACION POR SINIESTRO RG 126 - 2023 - UIF</option>
                            <option value={S11}>FCE - SIN - 11 - DENUNCIA DE SINIESTRO SEGUROS DE PERSONAS</option>
                            <option value={S12}>FCE - SIN - 12 - DENUNCIA DE SINIESTRO DE VIDA CON AHORRO</option>
                            <option value={S13}>FCE - SIN - 13 - DECLARACION JURADA DEL BENEFICIARIO - CESIONARIO</option>
                        </Form.Control>

                        <a className="border-0 p-2 btn-block btn-blue text-center text-light" href={formsS} download target="_parent">Descargar formulario</a>
                    </Form.Group>

                    <hr/>

                    <Form.Group className="py-2">
                        <Form.Label className="d-block text-center">Formularios de Inversiones</Form.Label>
                        <Form.Control as="select" onChange={handleClickI}>
                            <option></option>
                            <option value={I1}>FCE - INV - 01 - SOLICITUD DE RESCATE - VIDA CON AHORRO</option>
                        </Form.Control>

                        
                        <a className="border-0 p-2 btn-block btn-blue text-center text-light" href={formsI} download>Descargar formulario</a>
                    </Form.Group>

                    <hr/>

                    <Form.Group className="py-2">
                        <Form.Label className="d-block text-center">Formularios de Emisión</Form.Label>
                        <Form.Control as="select" onChange={handleClickE}>
                            <option></option>
                            <option value={E1}>FCE - EMI - 01 - DECLARACION JURADA PERSONA POLITICAMENTE EXPUESTA</option>
                            <option value={E2}>FCE - EMI - 02 - ASEGURADOS TOMADORES RG 126_2023 - U.I.F.</option>
                            <option value={E3}>FCE - EMI - 03 - TOMADORES ASEGURADOS RG 126_2023 - U.I.F. - PERSONAS JURIDICAS_PRINCIPAL.pdf</option>
                            <option value={E3A}>FCE - EMI - 03 - TOMADORES ASEGURADOS RG 126_2023 - U.I.F. - PERSONAS JURIDICAS - ANEXO.pdf</option>
                            <option value={E4}>FCE - EMI - 04 - INFORMACION A ASEGURADOS RG 126-2023 U.I.F. - ORGANISMOS PUBLICOS</option>
                            <option value={E5}>FCE - EMI - 05 - FORMULARIO DE BENEFICIARIOS</option>
                            <option value={E6}>FCE - EMI - 06 - SEGURO DE VIDA OBLIGATORIO - SOLICITUD DE POLIZA</option>
                            <option value={E7}>FCE - EMI - 07 - SCV - ADHESION Y DESIGNACION DE BENEFICIARIOS</option>
                            <option value={E8}>FCE - EMI - 08 - DDJJ SUJETO OBLIGADO - RESOLUCION 126-2023 U.I.F.</option>
                            <option value={E12}>FCE - EMI - 12 - SOLICITUD AFILIACION VIDA CON AHORRO</option>
                            <option value={E15}>FCE - EMI - 15 - DECLARACION JURADA DEL PAGADOR DE LA POLIZA</option>
                        </Form.Control>

                        <a className="border-0 p-2 btn-block btn-blue text-center text-light" href={formsE} download replace>Descargar formulario</a>
                    </Form.Group>

                </div>

            </div>
        </Layout>

    )

}