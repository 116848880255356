import React from 'react'
import { Link } from "react-router-dom";
import styled from 'styled-components';


const Div = styled.div`
    .btn-company-blue {
        border-radius: 0px
    }

`;

export default function Security() {

    return (
        <Div>
            <section className="Legal text-center">
                <Link to="/atencion-al-asegurado" className="btn-company-blue p-3">Atención al asegurado</Link>
                <Link to="/seguridad/lavado" className="btn-company-blue p-3">Prevención Lavado</Link>
                <Link to="/seguridad/fraude" className="btn-company-blue p-3">Lucha contra el fraude</Link>
                <Link to="/terminos-y-condiciones" className="btn-company-blue p-3">Terminos y condiciones</Link>
                <Link to="/politica-de-privacidad" className="btn-company-blue p-3">Política de Privacidad</Link>
            </section>
        </Div>
    )

}