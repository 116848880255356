import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import About from './pages/about';
import ClientService from './pages/clientservice';
import Forms from './pages/forms';
import Fquestions from './pages/fquestions';
import Rrhh from './pages/rrhh';
import TermsAndoConditions from './pages/termsandconditions';
import Fraude from './pages/security/fraude';
import Lavado from './pages/security/lavado';
import Privacy from './pages/security/privacidad';
import AnulacionPoliza from './components/anulacion-poliza';
import { AnimatePresence } from "framer-motion";

import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";




const routing = (

  <Router>
    <AnimatePresence exitBeforeEnter>
    <Switch>
      <Route exact path="/" component={App} />
      <Route exact path="/sobre-nosotros" component={About} />
      <Route exact path="/atencion-al-asegurado" component={ClientService} />
      <Route exact path="/formularios" component={Forms} />
      <Route exact path="/preguntas-frecuentes" component={Fquestions} />
      <Route exact path="/rrhh" component={Rrhh} />
      <Route exact path="/terminos-y-condiciones" component={TermsAndoConditions} />
      <Route exact path="/seguridad/fraude" component={Fraude} />
      <Route exact path="/seguridad/lavado" component={Lavado} />
      <Route exact path="/anulacion-poliza" component={AnulacionPoliza} />
      <Route exact path="/politica-de-privacidad" component={Privacy} />
    </Switch>
  </AnimatePresence>
</Router>

)



ReactDOM.render(
  <React.StrictMode>

    {routing}
   
  </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
