import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'


const Div = styled.div`

    .questions div {
        padding: 20px 20px;
        background: #009ade;
        border-radius: 10px;
        box-shadow: 1px 4px 5px #a5cbec;
        color: white;
        margin: 15px 0;
        transition: all 0.5s ease-in-out;
    }

    .questions div:hover {
        transform: scale(1.05);
    }

    @media screen and (max-width: 996px) {
        .questions div:hover {
            transform: scale(1);
        }
    }

`

export default function About()  {

    return(
        <Layout>
            <Div className="container-xl p-0">

                <div>


                    <h3 className="pt-3 px-2 text-lg-left text-center text-company"><b>Preguntas Frecuentes</b></h3>

                    <hr />

                    <div className="questions pb-3 px-lg-0 px-2">

                        <div>
                            <h5><strong>¿Cómo denuncio un siniestro?</strong></h5>
                            <p>Deberás solicitar en nuestras oficinas el formulario de denuncia correspondiente a la cobertura que deseás denunciar, o bien, descargarlo de esta página web, y presentarlo completo y firmado junto con toda la documentación que se detalla en el mismo.</p>
                        </div>
                        <div>
                            <h5><strong>¿En qué consiste un seguro de Vida?</strong></h5>
                            <p>El seguro de Vida funciona como un resarcimiento económico en caso de fallecimiento del asegurado para los beneficiarios designados o herederos legales del mismo.</p>
                        </div>
                        <div>
                            <h5><strong>¿Quién cobra un seguro de vida?</strong></h5>
                            <p>Si el asegurado firma una manifestación escrita, lo harán los beneficiarios que designe en ella; caso contrario, cobrarán los herederos legales.</p>
                        </div>
                        <div>
                            <h5><strong>¿A quién puedo designar como beneficiario? ¿Puedo designar a más de una persona?</strong></h5>
                            <p>Podés designar como beneficiario de tu seguro a la/s persona/s que elijas. También podés elegir la cantidad de beneficiarios que quieras y podés modificarlos cuando consideres necesario. En el caso de no haber designado ningún beneficiario, los mismos serán los herederos legales.</p>
                        </div>
                        <div>
                            <h5><strong>¿Puedo cambiar los beneficiarios designados? ¿Cómo?</strong></h5>
                            <p>Si, podés. Tenés que firmar el formulario de Designación de Beneficiarios correspondiente, el cuál te facilitará tu empleador.</p>
                        </div>
                        <div>
                            <h5><strong>¿En qué consiste la cobertura de Accidente o Enfermedad Inculpable?</strong></h5>
                            <p>Esta cobertura se basa en el Art. 208 de la ley de contrato de trabajo, el cual establece que el trabajador tendrá derecho a percibir su remuneración cuando se encuentre de licencia por accidente o enfermedad inculpable durante los períodos dispuestos conforme a su antigüedad y carga de familia. Tenés que tener en cuenta que para cada supuesto el empleador tendrá las siguientes franquicias a su exclusivo cargo: (I) un mes por cada contingencia en los casos en que la legislación laboral contemple el pago de remuneraciones por un periodo de hasta tres meses, (II) dos y tres meses por cada contingencia, según el caso, para los supuestos en que la legislación laboral contemple el pago de remuneraciones por un periodo de hasta seis y doce meses respectivamente. Los salarios que forman parte de esta cobertura serán abonados por el empleador y luego reintegrados por la aseguradora.</p>
                        </div>

                    </div>


                </div>

            </Div>
        </Layout>

    )

}