import React from 'react'
import Layout from '../components/layout'

export default function TermsAndConditions()  {

    return(
        <Layout>
            <div className="container-xl p-0">

                <div className="px-3 pt-3 text-lg-left text-center">
                    <h5 className="text-company">Terminos y Condiciones de Uso</h5>
                    <hr />

                    <div className="text-left">
                        <p>
                            <b>1 -</b> Los presentes términos y condiciones de uso (los “Términos y Condiciones”) serán aplicables a los sitios web ubicados en las dirección electrónica: www.edificarseguros.com.ar (en adelante denominado como el “Sitio Web”) de propiedad de Edificar Seguros S.A. (en adelante “Edificar Seguros S.A”) y/o a cualquiera de las páginas relacionadas que a través del mismo pueda accederse.
            
                        </p>
            
                        <hr />
            
                        <p>
                            <b>2 -</b> La utilización del Sitio Web implica la conformidad del cliente, usuario y/o visitante a los presentes Términos y Condiciones y a sus Políticas de Privacidad que regulan el uso del Sitio Web que Edificar Seguros S.A pone a disposición de los mismos en forma gratuita. En caso de no estar de acuerdo con estos Términos y Condiciones y/o con las Políticas de Privacidad le solicitamos se abstenga de utilizar el Sitio Web o de brindar cualquier tipo de información requerida por el mismo o por los formularios contenidos en el mismo.
                        </p>
            
                        <hr />
            
                        <p>
                            <b>3 -</b> Edificar Seguros S.A podrá modificar en cualquier momento los Términos y Condiciones del Sitio Web. Si Edificar Seguros S.A decidiera cambiar los mismos, haremos públicos dichos cambios a través del Sitio Web, por lo que usted deberá realizar una revisión periódica a fin de verificar los Términos y Condiciones vigentes al momento. De todas maneras, la continuación en el uso del Sitio Web implica la aceptación por parte del cliente, usuario y/o visitante de las modificaciones introducidas.
                        </p>
            
                        <hr />
            
                        <p>
                            <b>4 -</b> El acceso y la utilización del Sitio Web es de carácter gratuito y por tiempo indeterminado, sujeto al estricto cumplimiento por parte del cliente, usuario y/o visitante de los presentes Términos y Condiciones, así como el respeto por la legislación vigente. El cliente, usuario y/o visitante asume el compromiso de no utilizar el Sitio Web para fines prohibidos por la ley o contrarios a lo establecido en estos Términos y Condiciones, o de un modo que puedan causar daño a derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar el Sitio Web o impedir la normal utilización o disfrute del Sitio Web.
                        </p>
            
                        <hr />
            
                        <p>
                            <b>5 -</b> La adquisición de cualquier producto y/o servicio ofrecido por Edificar Seguros S.A se regirá específicamente por los términos de la correspondiente póliza de seguro o contrato aplicable y no por los Términos y Condiciones del Sitio Web.
                        </p>
            
                        <hr />
            
                        <p>
                            <b>6 -</b> El Sitio Web está destinado a la promoción de los productos y servicios de Edificar Seguros S.A. En ese sentido, la información que se brinda a través del Sitio Web tiene carácter meramente informativo y no constituye una oferta de venta ni una búsqueda de ofertas de compra de ningún producto a que se pudiera hacer referencia en el Sitio Web. De igual modo, la cotización de un seguro o de un producto a través del Sitio Web se realiza a través de un mecanismo de “simulación” de una cotización y no constituye una oferta concreta de ningún producto y/o servicio. A los fines de recibir una cotización exacta y una oferta de servicios y/o productos, el cliente, usuario y/o visitante deberá contactarse con un asesor de Edificar Seguros S.A.
                        </p>
            
                        <hr />
            
                        <p>
                            <b>7 -</b> Los servicios y/o productos de Edificar Seguros S.A promocionados a través del Sitio Web son ofrecidos para su contratación dentro de la República Argentina por lo que los contratantes que se encuentren fuera de dicho territorio, deberán constatar previamente la existencia de eventuales limitaciones legales para la adquisición de productos y/o servicios de Edificar Seguros S.A.
                        </p>
            
                        <hr />
            
                        <p>
                            <b>8 -</b> El cliente, usuario y/o visitante accede y utilizará el Sitio Web por decisión voluntaria y bajo su exclusiva responsabilidad. Edificar Seguros S.A no puede garantizar la disponibilidad y/o el funcionamiento continuo e ininterrumpido del Sitio Web. De igual modo, Edificar Seguros S.A no garantiza la utilidad del Sitio Web para la realización de ninguna actividad en particular, ni su infalibilidad, ni la adecuación de la información contenida en el mismo para fines específicos ya que la misma posee carácter meramente informativo y la contratación de cualquier producto y/o servicio determinado requiere del asesoramiento e intervención personal de un asesor de Edificar Seguros S.A.
                        </p>
            
                        <hr />
                        
                        <p>
                            <b>9 -</b> Edificar Seguros S.A desde ya deslinda todo tipo de responsabilidad legal por los daños y perjuicios de cualquier naturaleza que puedan derivarse de la falta de disponibilidad o de la falta de funcionamiento ininterrumpido del Sitio Web y/o sus servicios o contenidos. Asimismo, Edificar Seguros S.A no otorga ninguna garantía ni se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que se deriven de la falta de utilidad, adecuación o validez del Sitio Web y/o de sus servicios o contenidos para satisfacer necesidades, actividades o resultados concretos o expectativas de los clientes, usuarios y/o visitantes. Edificar Seguros S.A no asume por la puesta a disposición del Sitio Web el carácter de asesor legal, financiero, impositivo o de cualquier otra clase. Edificar Seguros S.A no asume responsabilidad alguna por el uso ilícito y/o contrario a los presentes Términos y Condiciones del Sitio Web.
                        </p>
            
                        <hr />
            
                        <p>
                            <b>10 -</b> En el caso en que Usted descargue del Sitio Web y utilice archivos disponibles -tipo planillas de cálculo- que contengan formularios a completar y estimaciones preliminares de cotizaciones (en adelante los “Archivos”), los mismos, incluyendo cualquier archivo de imágenes incorporados a éstos o generadas por éstos y toda la información que los acompañe, no podrán ser utilizados de forma que viole la normativa de propiedad intelectual aplicable. Los Archivos tienen carácter estimativo, indicativo y orientativo en forma exclusiva. Todos los contenidos del Sitio Web y los Archivos no cuentan con ninguna garantía, ya sea expresa o implícita. Edificar Seguros S.A no garantiza que todos los contenidos del Sitio Web y los Archivos: (i) se encontrarán actualizados y siempre disponibles; (ii) estarán libres de error y que los errores serán corregidos; y (iii) se encontrarán libres de virus u otro componente dañino. Edificar Seguros S.A tampoco garantiza que el servidor que hace disponibles a los contenidos del Sitio Web y los Archivos, se encuentra libre de virus u otros componentes dañinos. Edificar Seguros S.A no garantiza ni se hace responsable sobre los resultados del uso de los contenidos del Sitio Web y los Archivos, en lo que respecta a su confiabilidad, corrección o certeza.
                        </p>
            
                        <hr />
                            
                        <p>
                            <b>11 -</b> El Sitio Web tiene hipervínculos con otros sitios web, los cuales Edificar Seguros S.A no edita, controla, mantiene o supervisa de forma alguna, no siendo responsable por el contenido de dichos sitios web, resultando responsabilidad de su titular, por lo que Edificar Seguros S.A no avala o aprueba dichos contenidos.
                        </p>
            
                        <hr />
                        
                        <p>
                            <b>12 -</b> Todos los diseños, marcas, nombres, denominaciones, imágenes, logos, gráficos, iconos, nombres de dominio y todo otro contenido del Sitio Web pertenecen a Edificar Seguros S.A o sólo pueden ser utilizados por Edificar Seguros S.A. Todos los derechos de propiedad intelectual y copyright de los contenidos de este Sitio Web se reservan a Edificar Seguros S.A y a sus licenciantes. Cualquier copia o uso no autorizado del diseño o contenido que difiera del expresamente permitido por Edificar Seguros S.A, en su carácter de titular de los derechos de propiedad intelectual, afectará los derechos del mencionado titular conforme los tratados internacionales y la normativa aplicable y vigente de la República Argentina. Queda estrictamente prohibida la utilización de cualquiera de los contenidos provistos en el Sitio Web, con cualquier propósito o de cualquier manera, excepto cuando mediare autorización expresa, previa y por escrito de Edificar Seguros S.A. Las conexiones con el Sitio Web quedan prohibidas, excepto se cuente con el permiso previo y expreso de Edificar Seguros S.A. El cliente, usuario y/o visitante podrá descargar del Sitio Web los Archivos y aquellos materiales que así se autoricen o permitan o fueran dispuestos por Edificar Seguros S.A con esa finalidad, con la obligación de utilizarlos en forma personal y no pudiendo modificarlos en ningún aspecto, ni redistribuirlos, transmitirlos, hacerlos circular o cualquier otro acto del que se pueda entender que existe alguna forma de titularidad de su parte de dicho material.
                        </p>
            
                        <hr />
                            
                        <p>
                            <b>13 -</b> El acceso y utilización del Sitio Web no posee limitación temporal alguna. Sin perjuicio de ello, Edificar Seguros S.A podrá dar por terminado dicho acceso en cualquier momento sin expresión de causa y sin que ello genere derecho alguno a favor de los clientes, usuarios y/o visitantes del Sitio Web.
                        </p>
            
                        <hr />
                            
                        <p>
                            <b>14 -</b> Cualquier controversia o conflicto que pueda surgir como consecuencia del uso del Sitio Web será resuelto de acuerdo con la legislación vigente en la República Argentina y deberá someterse obligatoriamente a la jurisdicción y competencia de los Tribunales Ordinarios de la Ciudad de Buenos Aires (República Argentina) renunciando expresamente a cualquier otro fuero o jurisdicción que pudiere corresponder.
                        </p>
                
                    </div>
                </div>
                

            </div>

        </Layout>

    )

}