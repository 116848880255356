import React from 'react'
import ModalQr from '../components/modal'
import styled from 'styled-components';

import SSN from '../assets/SSN.png'


const Div = styled.div`

    .img-grid {
        justify-content: space-evenly;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-items: center;
    }

    #bottom {
        display: grid;
        grid-template-columns: 8fr 1fr
    }

    #bottom img {
        padding-left: 1.5px;
    }

    .Legals {
        display: grid;
        grid-template-columns: repeat(5, 1fr)
    }
    .Legals div {
        height:100%;
    }
    .Legals p {
        margin: 0px;
        line-height: 1.2;
        height: 100%;
        display: grid;
        align-content: center;
        color: #000000cc;
    }

    section p {
        font-size: 12px;
    }

    @media screen and (max-width: 996px) {

        #bottom {
            display: grid;
            grid-template-columns: 1fr
        }
        
        section p {
            font-size: 12px;
            padding: 5px;
        }
        .Legals {
            grid-template-columns: 1fr;
            grid-gap: 20px;
        }
        .Legals div {
            border: 0 !important
        }
    }



`

export default function Legals()  {

    return(

        <Div>
            <section className="Legals text-center pb-2 pb-lg-5 pt-2 ">
                <div>
                    <p>N° de inscripción en SSN<br/>0080 </p>
                </div>
                <div className="border-left">
                    <p>Atención al asegurado<br/>0800-666-8400</p>
                </div>
                <div className="border-left">
                    <p>Organismo de control</p>
                </div>
                <div className="border-left">
                    <p>www.argentina.gob.ar/ssn</p>
                </div>
                <div className="border-left">
                    <img src={SSN} width="200px" alt=""/>
                </div>
            </section>

            <section className="pl-3 text-lg-left text-center">

                <p>Horario de atención, Lunes a Viernes de 10:00 hs a 17:00 hs</p>
                <p>La entidad aseguradora dispone de un Servicio de Atención al Asegurado que atenderá las consultas y reclamos que presenten los tomadores de seguros, asegurados, beneficiarios y/o derechohabientes.El servicio de Atención al Asegurado está integrado por:</p>
                <p><strong>RESPONSABLE:</strong> Moumdjian Natalia / Int 3203 / Mail: moumdjian.n@edificarseguros.com.ar</p>
                <p><strong>SUPLENTE:</strong> Gabriel Di Bella / Int 3272 / Mail: dibella.g@edificarseguros.com.ar </p>
                <p>En caso de que el reclamo no haya sido resuelto o haya sido desestimado, total o parcialmente, o que haya sido denegada su admisión, podrá comunicarse con la Superintendencia de Seguros de la Nación por teléfono al 0800-666-8400, correo electrónico a denuncias@ssn.gob.ar o por formulario web a través de www.argentina.gob.ar/ssn.</p>

            </section>


            <section id="bottom" className="py-5 mb-5">
                <p className="pl-3 text-center text-md-left m-0" style={{ alignSelf: 'center' }}>EDIFICAR SEGUROS S.A. © 2017-2020 Todos los derechos reservados</p>
                <div className="img-grid">

                    <a href="http://qr.afip.gob.ar/?qr=eRqV_rTAMbjopBmmnhH2XQ,," target="_F960AFIPInfo"><img src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" width="30" /></a>

                    <ModalQr />

                </div>
            </section>



        </Div>

    )


}