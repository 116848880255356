import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'

import Img4A from '../assets/4A.jpg'

const Div = styled.div`

    .img4A {
        width: 100%;
        height: 400px;
        object-fit: cover;
    }

`

export default function RRHH() {

    return(
        <Layout>
            <Div className="container-xl p-0 ">

                <div className="px-lg-0 px-2 text-lg-left text-center">
                        
                    <h3 className="pt-3 pl-3 text-company"> <b>Recursos Humanos</b> </h3>
                    <p className="pl-3">Edificar Seguros es una compañía sumamente interesada en el desarrollo profesional de sus recursos humanos brindando capacitaciones, beneficios y puestos acorde a las capacidades particulares de cada integrante.</p>
                    <p className="pl-3">Buscamos personas que quieran formar parte de nuestro equipo de trabajo aportado creatividad y profesionalismo</p>

                </div>
                
                <img className="img4A mt-4" src={Img4A} alt=""/>

            </Div>
        </Layout>

    )

}