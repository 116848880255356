import React from 'react'
import Navigation from './navigation'
import Footer from './footer'
import Legals from './legals'
import { motion } from "framer-motion";
import Security from './security';
// import ButtonToTop from '../components/buttonToTop'

export default function Layout(props) {

    window.scrollTo(0, 0)

    window.beforeunload = () => {
        window.scrollTo(0, 0) 
    }


    const pageVariants = {
        
        in: {
            opacity: 1,
            scale:1
        },
        out: {
            opacity: 0,
            scale:0.99
        },
       
    }

    const pageTransition = {
        type: "tween",
        ease: "easeOut",
        duration: 0.6
    }
     

   return (

        <div>

            <Navigation />

            <motion.div initial="out" animate="in" exit="out" variants={ pageVariants } transition={ pageTransition }>
                <div>
                    {props.children}
                </div>

                <div className="container-xl p-0">
                    <Security />
                    <Footer />
                    <Legals />
                </div>

            </motion.div>
            
        </div>
    
    )

} 

