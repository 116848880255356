import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons';
library.add(fas)



const Div = styled.div`

    a {
        color: white;
    }
    a:hover {
        color: white;
    }

    .middle p {
        margin: 0;
        line-height: 1.2;
    }

    .middle {
        margin: 10px 0;
        display: grid;
        grid-template-columns: 1fr 6fr
    }

    .middle div:first-child {
        text-align: center;
    }

    #footer {
        background: #0074af;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px
    }

    @media screen and (max-width: 996px) {
        .middle {
            display: grid;
            grid-template-columns: 1fr;
        }

        #footer {
            grid-template-columns: 1fr;
            text-align: center;
        }
    }

`

export default function Footer() {

    return (
        <Div>
            <div id="footer" className="py-5 text-light">
                <div style={{ alignSelf: 'center' }}>

                    <img className="text-center m-auto d-block" width="80px" src="/Logotipo-white.png" alt="" />
                    <p className="text-center">Edificar seguros © 2017 - 2020</p>

                </div>
                <div>

                    <h4>Información de contacto</h4>
                    <div>
                        <div className="middle">
                            <div>
                                <FontAwesomeIcon icon="map-marker-alt" className="py-3 fa-3x" style={{ height: '60px' }} />
                            </div>
                            <div style={{ alignSelf: 'center' }}>
                                <p>Dirección <br /> Riobamba 429 Piso 15 CABA - CP C1025ABI</p>
                            </div>
                        </div>
                        <div className="middle">
                            <div>
                                <FontAwesomeIcon icon="phone" className="py-3 fa-3x" style={{ height: '60px' }} />
                            </div>
                            <div style={{ alignSelf: 'center' }}>
                                <p>Teléfonos <br /> 0810-999-3343</p>
                            </div>
                        </div>
                        <div className="middle">
                            <div>
                                <FontAwesomeIcon icon="users" className="py-3 fa-3x" style={{ height: '60px' }} />
                            </div>
                            <div style={{ alignSelf: 'center' }}>
                                <p>Mail <br /> <a className="text-light" href="mailto:info@edificarseguros.com.ar">info@edificarseguros.com.ar</a> </p>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="px-3" style={{ alignSelf: 'center' }}>

                    <h4>Sobre la compañia</h4>
                    <p>Edificar Seguros está conformado por un equipo de profesionales capacitados para brindar un buen servicio en seguros.</p>

                </div>


            </div>
        </Div>
    )

}



