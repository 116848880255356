import React from 'react'
import Layout from '../../components/layout'

export default function Privacy() {

    return (
        <Layout>
            <div className="container-xl pt-3">
                <h5 className="text-company">POLITICA DE PRIVACIDAD</h5>
                <hr />

                <div className="pt-3 px-3 text-lg-left text-center">

                    <p>
                        <strong>POLITICA DE PRIVACIDAD EDIFICAR SEGUROS S.A</strong> se encuentra comprometida con el resguardo de la intimidad de sus clientes y usuarios. En virtud de ello, procura tomar los recaudos necesarios para que los clientes y usuarios de su Sitio Web se encuentren protegidos, entendiendo que el uso o visita al mismo será sin ninguna intensión maliciosa o dolo. <strong>EDIFICAR SEGUROS S.A</strong> tiene políticas, normas y procedimientos relativos a la seguridad física y lógica, las que están en permanente revisión, actualización y mejora, para el cumplimiento de las leyes y regulaciones a nivel nacional que así lo exigen para la protección de los datos personales. No obstante, ponen en conocimiento de los usuarios que el material y la información contenidos en este sitio son provistos en el estado en que se encuentra y su veracidad o exactitud no se encuentra garantizada ni expresa ni implícitamente, por lo que declina toda responsabilidad al respecto. Dicha información no se encuentra libre de imprecisiones técnicas, errores tipográficos, referencias a productos o servicios discontinuados o no disponibles en el país de acceso u otros errores. Le pedimos que lea atentamente la misma antes de suministrar cualquier dato personal.
                    </p>

                    <p>
                        Acceso sitio de terceros a través de nuestro sitio Web
                        <strong>EDIFICAR SEGUROS S.A</strong> no se responsabiliza por el actuar de los sitios web a los cuales se puede acceder por medio del sitio, por lo que recomendamos la lectura de la Política de Privacidad de cada uno ellos.
                    </p>

                    <p>
                        <strong>Confidencialidad de Datos
                            EDIFICAR SEGUROS S.A</strong> garantiza la privacidad y confidencialidad de los Datos de Carácter Personal proporcionados por sus titulares de conformidad con lo establecido en el Artículo 43, párrafo tercero de la Constitución Nacional, en la Ley N° 25.326 de Protección de Datos Personales, en el Decreto N° 1558/2001 y en la Disposición 11/2006 de la Dirección Nacional de Protección de Datos Personales.
                    </p>

                    <p>
                        Medios de información
                        Nuestro sistema podría obtener información automáticamente acerca de su conducta cuando navega por el sitio, a través de los siguientes medios de información:
                    </p>

                    <p>
                        <b> * Información del navegador de Internet:</b> La mayoría de los sitios web recogen cierta información, como su dirección de IP (es decir, la dirección de la computadora en Internet), resolución de pantalla, tipo de sistema operativo (Windows o Mac) y versión, tipo de navegador de Internet y versión, tiempo de la visita y las páginas visitadas. Usamos esta información con fines como calcular los niveles de uso del Sitio, ayudar a diagnosticar problemas del servidor y administrar el Sitio.
                    </p>

                    <p>
                        <b> * Información recopilada a través de cookies, tags pixel y otras tecnologías.</b> Las cookies son porciones de información almacenada directamente en la computadora que se está utilizando. Las cookies nos permiten reconocer su computadora y recopilar información como el tipo de navegador de Internet, el tiempo pasado en el Sitio, las páginas visitadas y las preferencias de idioma. Podemos utilizar la información para fines de seguridad, para facilitar la navegación, para mostrar la información de manera más eficaz, para personalizar su experiencia al visitar el Sitio, o para recopilar información estadística sobre el uso del Sitio. También podemos utilizar cookies para rastrear sus respuestas a nuestros anuncios, y utilizar cookies u otros archivos para rastrear el uso de otros sitios web.
                    </p>

                    <p>
                        <b> * Información demográfica y otra información que nos proporcione.</b>
                    </p>

                    <p>
                        <b> * Información agregada.</b>
                    </p>

                    <p>
                        <b>  * De usted:</b> Parte de la información (por ejemplo, su ubicación o medio de comunicación preferido) se obtiene cuando usted la proporciona voluntariamente. Salvo que se combine con su Información personal, esta información no lo identifica personalmente.
                    </p>

                    <p>
                        <b> * Información específica sobre usted:</b> En ciertos casos EDFICAR SEGUROS S.A tiene la necesidad de acceder a datos personales suyos, dependiendo su relación con la Compañía (por ejemplo, como titular de la póliza; asegurado no titular de la póliza o tercero; testigo, productor, bróker o agente institorio, o bien otra persona relacionada con nuestro negocio). La Información personal recopilada sobre usted y las personas a su cargo puede incluir: Identificación general e información de contacto. Su nombre, dirección, detalle de correo electrónico y teléfono, sexo, estado civil, situación familiar, fecha de nacimiento, contraseñas, nivel educativo, atributos físicos, registros de actividad (tales como el historial de tránsito), fotos, historia laboral, habilidades y experiencia, licencias profesionales y afiliaciones, relación con el titular de la póliza, asegurado o reclamante, y la fecha y la causa de muerte, lesiones o discapacidades.
                    </p>

                    <p>
                        <b>* Números de identificación expedidos por organismos o agencias gubernamentales.</b> Número de Documento, número de pasaporte, número de identificación fiscal, número de identificación militar, número de registro de conducir u otro número de licencia.
                    </p>

                    <p>
                        <b> * Números de identificación expedidos por organismos o agencias gubernamentales.</b> Número de Documento, número de pasaporte, número de identificación fiscal, número de identificación militar, número de registro de conducir u otro número de licencia.
                    </p>

                    <p>
                        <b> * Información financiera y detalles contables. </b>Número de tarjeta de crédito, número de cuenta bancaria y datos de la cuenta; historial de crédito y puntaje de crédito; activos, ingresos; y otra información financiera.
                    </p>

                    <p>
                        <b>  * Condición médica y estado de salud.</b> En ciertos casos podemos recibir información sensible acerca de Enfermedades físicas o mentales, o condición médica (actual o anterior), estado de salud, información sobre lesiones o discapacidades, procedimientos médicos realizados, hábitos personales (por ejemplo, tabaquismo o consumo de alcohol), información sobre medicamentos recetados e historia clínica.
                    </p>

                    <p>
                        <b> * Otra información sensible.</b> Además, en otros casos –cuando medien razones de interés general autorizadas por ley podemos recibir información sensible acerca de su afiliación sindical, creencias religiosas, opiniones políticas, historia médica familiar o información genética (por ejemplo, si usted solicita un seguro a través de un socio comercial que es una organización comercial, religiosa o política). Además, podemos obtener información sobre sus antecedentes penales o historial de juicios civiles durante el proceso de prevención, detección e investigación de fraudes. También podemos obtener información sensible si usted voluntariamente la proporciona (por ejemplo, si usted expresa sus preferencias con respecto al tratamiento médico en base a sus creencias religiosas).
                    </p>

                    <p>
                        <b> * Grabaciones telefónicas.</b> Grabaciones de llamadas telefónicas a nuestros representantes y centros de llamadas.
                    </p>

                    <p>
                        <b> * Información para investigar delitos, incluidos fraude y lavado de dinero.</b> Cuando el cumplimiento de normativa específica lo requiera.
                    </p>

                    <p>
                        En el caso de que Ud. proporcione información personal a <b> EDIFICAR SEGUROS S.A</b> en relación a otras personas, Ud. acepta: (a) informar a la persona sobre el contenido de esta Política de privacidad, y (b) obtener el consentimiento legalmente necesario para la recopilación, uso, divulgación y transferencia (incluso la transferencia a otros países) de Información personal acerca de la persona de acuerdo con esta Política de privacidad no siendo <b>EDIFICAR SEGUROS S.A</b> responsable por el uso desautorizado que Ud. destine a la Información Personal de terceros.
                    </p>

                    <p>
                        Protección de Datos
                        <b> EDIFICAR SEGUROS S.A</b> protege la privacidad de cada persona de la siguiente forma:
                    </p>

                    <p>
                        <b> * Recopilando información en forma razonable y sólo recopilando información que necesitamos para prestar servicios de seguro.</b>
                    </p>

                    <p>
                        <b> * Utilizando la información personal sólo para nuestras operaciones comerciales y para cumplir con la ley.</b>
                    </p>

                    <p>
                        <b> * Conservando la información personal sólo durante el tiempo necesario y protegiéndola.</b>
                    </p>

                    <p>
                        <b> * Garantizando que todos los derechos individuales puedan ser ejercidos en el marco de la legislación de Protección de Datos.</b>
                    </p>

                    <p>
                        <b> EDIFICAR SEGUROS S.A</b> puede ceder el tratamiento de los datos personales, a los efectos de cumplir con las obligaciones asumidas y dentro de los límites establecidos por el consentimiento de su titular y de lo dispuesto por el marco normativo, a las siguientes personas u entidades:
                    </p>

                    <p>
                        <b> * Otras aseguradoras:</b> En el desarrollo de la comercialización y el ofrecimiento de un seguro, y en el procesamiento de reclamos, EDIFICAR SEGUROS S.A puede llegar a poner la Información personal a disposición de terceros, como otras aseguradoras, reaseguradoras, corredores de seguros y agentes de reaseguros y otros intermediarios y agentes, representantes designados, entre otros.
                    </p>

                    <p>
                        <b> * Nuestros proveedores de servicios.</b> Terceros externos proveedores de servicios, tales como médicos, contadores, actuarios, auditores, expertos, abogados y otros asesores externos profesionales; proveedores de viajes y asistencia médica; proveedores de servicios de centros de llamadas; proveedores de servicios de sistemas de IT, apoyo y alojamiento; proveedores de servicios de impresión, publicidad, comercialización e investigación de mercado, y análisis; bancos e instituciones financieras que atienden nuestras cuentas, liquidadores de siniestros; proveedores de gestión de documentos y registros; investigadores y ajustadores de siniestros; asesores legales, traductores, y otros vendedores y proveedores de servicios externos que nos ayudan a llevar a cabo actividades comerciales, todos ellos en cumplimiento en las políticas de ética y confidencialidad correspondientes y adhiriendo a los distintos procedimientos a fines de cumplir con las políticas de protección de datos e información.
                    </p>

                    <p>
                        <b> * Autoridades gubernamentales y terceros implicados en acciones judiciales en cumplimiento de las disposiciones</b>
                    </p>

                    <p>
                        Finalidad de la recopilación de información
                        <b> EDIFICAR SERGUROS S.A</b> podría recoger información personal en el sitio para autenticar al cliente o proveedor; dar un servicio al cliente; realizar encuestas entre clientes y elaborar resultados; tomar decisiones sobre la posibilidad de ofrecer seguros; prestar servicios de seguros y asistencia (incluidas la evaluación, tramitación y liquidación de siniestros); prevenir, detectar e investigar delitos, incluidos fraude y lavado de dinero; personalizar su experiencia en el sitio; identificarlo ante las personas a quien envié mensajes a través del sitio; administrar nuestra estructura y operaciones comerciales, y cumplir con las políticas y procedimientos internos, incluidos los relativos a auditorias, finanzas y contabilidad, facturación y cobro, entre otras.
                    </p>

                    <p>
                        <b> Accesos menores:</b>
                        El Sitio no está destinado al uso de personas que tengan menos de dieciocho (18) años, y sugerimos que estas personas no incluyan Información personal en el Sitio. Pese a ello, en el caso de utilización del sitio por un menor de edad, entendemos que pueden no comprender debidamente la presente Política de Privacidad y sus implicancias, ni decidir válidamente sobre las opciones disponibles para nuestros clientes y usuarios, por lo que instamos a todos los padres o representantes o adultos bajo cuya supervisión.
                    </p>

                    <p>
                        Derecho de acceso, rectificación y supresión de los datos registrados
                        <b> EDIFICAR SEGUROS S.A</b> reconoce al Titular de los Datos Personales, previa acreditación de su identidad, el derecho a solicitar y a obtener información sobre sus datos personales incluidos en sus registros, dentro de los diez días hábiles desde la solicitud, de conformidad con lo establecido en la <b>Ley 25.326</b> de protección de Datos Personales. EDIFICAR SEGUROS S.A garantiza también al Titular de los Datos Personales el derecho a obtener la rectificación, actualización y, cuando corresponda, la supresión de los datos personales de los que sea titular, que estén incluidos en su banco de datos y garantiza la rectificación, supresión o actualización de los mismos en el plazo máximo de cinco días hábiles de recibido el reclamo del titular de los datos.
                    </p>

                    <p>
                        <b>  En cumplimiento de la disposición 10/2008 de la Dirección Nacional de Protección de Datos Personales se hace saber que:</b> El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley N° 25.326.- La DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES, Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales”.
                    </p>

                    <p>
                        <b>  Actualización de la Política de Privacidad de los Datos
                            EDIFICAR SEGUROS S.A</b> podrá rever, revisar y/o actualizar permanentemente todos los aspectos relativos a la Seguridad y Privacidad de su sitio. En el momento que las mismas sean actualizadas, le será informado por esta vía, de manera que usted siempre pueda saber qué información recogemos, cuál será el uso que le daremos a la misma.
                    </p>

                    <p>
                        Aceptación de estos términos
                        Al utilizar nuestro sitio, Ud. está manifestando conocer nuestra Política de Privacidad y de los Términos y Condiciones de uso del sitio web, por lo que consideramos aceptadas las mismas.
                    </p>

                    <p>
                        Propiedad intelectual
                        Todas las marcas comerciales, dibujos, emblemas, imágenes, logotipos, isotipos, combinaciones de colores, combinación de letras y números, frases publicitarias y todo otro signo con capacidad distintiva de los productos o servicios de las empresas del grupo que aparezcan publicados en este sitio web son propiedad de <b>EDIFICAR SEGUROS S.A</b> y se encuentran protegidos por las leyes que regulan la Propiedad Intelectual, las Marcas y Patentes.
                    </p>

                    <p>
                        Ningún contenido puede interpretarse como una concesión, bajo ninguna forma, de licencias o derechos para hacer uso de ningún material comercial exhibido en este sitio web sin permiso escrito de la Compañía.
                    </p>

                    <p>
                        Se pueden imprimir, copiar, descargar o almacenar en forma temporaria fragmentos del sitio de <b>EDIFICAR SEGUROS S.A</b> para su información personal o cuando use algún producto y/o servicio. No se podrá alterar ni modificar ningún material que se imprima o descargue de este sitio, incluyendo, sin limitación alguna, la supresión de marcas identificatorias o leyendas en dicho material. No se podrá distribuir este material a terceros. Se prohíbe cualquier otro uso a menos que se solicite y obtenga de <b>EDIFICAR SEGUROS S.A</b> el consentimiento escrito previo. En especial, nadie puede utilizar una parte del sitio de la Compañía en ninguna otra página, o vincular cualquier otro sitio con el sitio de EDIFICAR SEGUROS S.A, sin la autorización escrita previa.
                    </p>

                    <p>
                        <b> No se puede duplicar, distribuir, publicar, transferir, transmitir, copiar, alterar, vender, usar para generar trabajos derivados, ni incurrir en el mal uso de los contenidos de terceros proveedores disponibles en el sitio de EDIFICAR SEGUROS S.A.</b>
                    </p>

                    <p>
                        Queda terminantemente prohibido el uso del sitio de <b>EDIFICAR SEGUROS S.A</b> para propósitos ilegales, abusivos, difamatorios, obscenos o amenazadores.
                    </p>

                    <p>
                        Consultas sobre información personal
                        Todas las preguntas acerca del uso de información personal se deben efectuar utilizando los siguientes detalles de contacto:
                    </p>

                    <p>
                        <b>* Correo Electrónico: info@edificarseguros.com.ar.</b>
                    </p>

                    <p>
                        <b> * Responsable del tratamiento: Sector de Legales.</b>
                    </p>

                    <p>
                        <b> * Dirección: Riobamba 429, P.18º, (C1025ABI), Ciudad Autónoma de Buenos Aires, Argentina.</b>
                    </p>

                    <p>
                        <b> * Tel: 5354-6801 o 0810 999 3343.</b>
                    </p>

                    <p>
                        <b> * Página Web: www.edificarseguros.com.ar</b>
                    </p>


                </div>


            </div>
        </Layout>

    )

}