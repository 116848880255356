import React, { useState, useEffect } from 'react'
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap'
import { Link } from "react-router-dom";
import Logo from '../assets/Logotipo.svg'
import iso from '../assets/iso.svg'
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp  } from '@fortawesome/free-brands-svg-icons';
library.add(fas)



const Div = styled.div`

    position: sticky;
    top: 0;
    z-index: 999;

    iso {
        width:100%;
    }



    @media screen and (min-width:996px) {

        .sign-button-top {
            display: none;
        }

        .sign-button {
            color: white !important;
            background: #e94f24;
            border-radius: 10px
        }
    }

    @media screen and (max-width:996px) {

        .brand-img {
            width: 50px !important;
        }

        .sign-button {
            display: none
        }

        .sign-button-top {
            font-size: 12px;
            padding: 10px 10px
        }
    }

}`;

export default function Navigation()  {

    const [width, setWidth] = useState(window.innerWidth);


    const innerWidth = () => {
        setWidth(window.innerWidth);
      };
    
    
      useEffect(() => {
        window.addEventListener("resize", innerWidth);
        return () => window.removeEventListener("resize", innerWidth);
    });

    return(

        <Div>
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="p-3">
                <div className="container-xl">
                    <Navbar.Brand as={Link} to="/">
                        <img className="brand-img" src={width < 992 ? iso : Logo} width="150px" alt=""/>
                    </Navbar.Brand>

                    <Button variant="light" className="sign-button-top btn-company" href="http://miseguro.edificarseguros.com.ar/#/login" target="_blank"><FontAwesomeIcon icon="sign-in-alt" /> Ingreso a mi Seguro</Button>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <NavDropdown title="Institucional" id="collasible-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/sobre-nosotros">Quienes somos?</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/rrhh">Recursos Humanos</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={HashLink} to="/#services">Servicios</Nav.Link>
                        <NavDropdown title="Clientes" id="collasible-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/preguntas-frecuentes">Preguntas Frecuentes</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/formularios">Formularios</NavDropdown.Item>    
                            <NavDropdown.Item as={Link} to="/anulacion-poliza">Arrepentimiento de contratación</NavDropdown.Item>    
                        </NavDropdown>
                            <Nav.Link as={HashLink} to="/#contactus">Contacto</Nav.Link>
                            <Nav.Link as={HashLink} to="/#contactus">
                                <FontAwesomeIcon icon="phone" color="secondary" style={{ height: '14px' }} /> 0810-999-3343
                            </Nav.Link>
                       
                        <Nav.Link href="https://wa.me/541122756141" target="_blank">
                            <FontAwesomeIcon icon={faWhatsapp} color="secondary" style={{ height: '18px' }} /> (11) 2275-6141
                        </Nav.Link>
                        <Nav.Link className="sign-button" href="http://miseguro.edificarseguros.com.ar/#/login" target="_blank"><FontAwesomeIcon icon="sign-in-alt" color="secondary" style={{ height: '14px' }} /> Ingreso a mi Seguro</Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </Div>

    ) 

}