import React, { useState, useEffect } from 'react'
import { Carousel } from 'react-bootstrap'
import styled from 'styled-components';
import F0 from '../assets/flyer-movil.svg'
import F1 from '../assets/F1.jpg'
import F2 from '../assets/F2.jpg'
import F3 from '../assets/F3.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp  } from '@fortawesome/free-brands-svg-icons';
library.add(fas)



const Div = styled.div`

    #btn-whatsapp {
        display:grid;
        grid-template-columns: 4fr 1fr;
        position: absolute;
        right: 10%;
        bottom: 10%;
        background: #82c91eec;
        color: white;
        text-align:left;
        padding:20px;
    }

    #btn-whatsapp:hover {
        box-shadow: 1px 4px 4px #4c7216ec;
    }

    @media screen and (max-width: 992px) {
        #btn-whatsapp {
            display: none;
        }
    }

`

export default function Slider(props) {

    const [width, setWidth] = useState(window.innerWidth);


    const innerWidth = () => {
        setWidth(window.innerWidth);
      };
    
    
      useEffect(() => {
        window.addEventListener("resize", innerWidth);
        return () => window.removeEventListener("resize", innerWidth);
    });


    return (

        <Carousel variant="dark" fade>
{/* 
            <Carousel.Item interval={5000} className={props.D0}>
                <Div>

                    <img
                        id="firstImg"
                        className="d-block w-100"
                        src={ width > 992 ? props.Img0 : F0 }
                        alt="imagen 1"
                    />


                    <a id="btn-whatsapp" className="group-wtp btn" href="http://wa.me/541122756141" target="_blank">
                        <div style={{ alignSelf:'center' }}>
                            <p className="contactowtp m-0">INGRESA ACÁ</p>
                            <p className="horariowtp m-0">DE LUNES A VIERNES - 09:30 A 18:00 HS</p>
                        </div>
                        <div className="text-center">
                            <FontAwesomeIcon icon={faWhatsapp} className="py-3 fa-5x" color="#fffff" />
                        </div>
                    </a>

                    

                </Div>
            </Carousel.Item> */}
            
            <Carousel.Item interval={3000}>
                <img
                    className="d-block w-100"
                    src={ width > 992 ? props.ImgA : props.ImgA }
                    alt="imagen 2"
                />
            </Carousel.Item>
            <Carousel.Item interval={3000}>
                <img
                    className="d-block w-100"
                    src={ width > 992 ? props.ImgB : props.ImgB }
                    alt="imagen 3"
                />
            </Carousel.Item>
            <Carousel.Item interval={3000}>
                <img
                    className="d-block w-100"
                    src={ width > 992 ? props.ImgC : props.ImgC }
                    alt="imagen 4"
                />
            </Carousel.Item>
        </Carousel>
    
    )

} 

