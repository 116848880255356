import React from "react";
import Layout from "../components/layout";
import { Button } from "react-bootstrap";

import R1 from "../assets/files/FCE - SAA - 01 - FORMULARIO DE RECLAMO.pdf";

export default function ClientService() {
  return (
    <Layout>
      <div className="container-xl p-0">
        <div className="px-2 text-lg-left text-center">
          <h5 className="pt-3 text-company">ATENCIÓN AL ASEGURADO</h5>
          <hr />
          <p>
            La entidad aseguradora dispone de un Servicio de Atención al
            Asegurado que atenderá las consultas y reclamos que presenten los
            tomadores de seguros, asegurados, beneficiarios y/o
            derechohabientes.El servicio de Atención al Asegurado está integrado
            por:
          </p>

          <p>
            <b className="text-company">RESPONSABLE:</b> Sra. MOUMDJIAN, NATALIA
            / Int 3203 / Mail: moumdjian.n@edificarseguros.com.ar
          </p>

          <p>
            <b className="text-company">1º ASISTENTE:</b> Sr. DI BELLA, GABRIEL
            / Int 3272 / Mail: dibella.g@edificarseguros.com.ar
          </p>

          <p>
            En caso de que el reclamo no haya sido resuelto o haya sido
            desestimado, total o parcialmente, o que haya sido denegada su
            admisión, podrá comunicarse con la Superintendencia de Seguros de la
            Nación por teléfono al 0800-666- 8400, correo electrónico a
            consultas@ssn.gob.ar o por formulario web a través de
            www.argentina.gob.ar/ssn.
          </p>

          <div className="text-center m-auto">
            <h6 className="m-0 ">
              <b className="text-company">Descargar formulario</b>
            </h6>
            <span className="fa fa-file-pdf-o"></span>

            <Button
              variant="light"
              className="btn-company"
              href={R1}
              target="blank"
              download
            >
              FCE - SAA - 01 - FORMULARIO DE RECLAMO
            </Button>

            <p>Formulario Editable.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
